<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <v-card elevation="6">
          <v-card-title class="pt-5 pb-2">
            <span class="mx-auto h6">{{ $t("generic.lang_startShift") }}</span>
          </v-card-title>
          <v-divider class="pa-0 ma-0" />
          <v-card-title class="pt-6 pb-6">
            <span class="mx-auto bolds">{{ startShiftTotal | currency }}</span>
          </v-card-title>
          <v-divider class="pa-0 ma-0" />

          <v-card-text class="pa-0">
            <v-data-table
              :headers="datatableHeaders"
              :items="startShiftDatatableItems"
              class="elevation-0"
              :loading="loading"
            >
              <template v-slot:item.total="{ item }">
                {{ (item.value * item.qty) | currency }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card elevation="6">
          <v-card-title class="pt-5 pb-2">
            <span class="mx-auto h6">{{ $t("generic.lang_endShift") }}</span>
          </v-card-title>
          <v-divider class="pa-0 ma-0" />
          <v-card-title class="pt-6 pb-6">
            <span class="mx-auto bolds">{{ endShiftTotal | currency }}</span>
          </v-card-title>
          <v-divider class="pa-0 ma-0" />

          <v-card-text class="pa-0">
            <v-data-table
              :headers="datatableHeaders"
              :items="endShiftDatatableItems"
              class="elevation-0"
              :loading="loading"
            >
              <template v-slot:item.total="{ item }">
                {{ (item.value * item.qty) | currency }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ENDPOINTS } from "../../../config";
import { Events } from "@/plugins/events";

var self = null;
export default {
  name: "DailyShiftComponent",

  data: () => {
    return {
      ENDPOINTS,
      loading: false,
      startShiftDatatableItems: [],
      endShiftDatatableItems: [],
    };
  },
  mounted() {
    this.loadData();
    self = this;
  },
  computed: {
    currencyIMG() {
      return this.$store.getters["settings/getSettingValue"](
        "geoloc_systemcountry"
      ) === "ma"
        ? "mad"
        : "euro";
    },
    startShiftTotal() {
      let somme = 0;
      this.startShiftDatatableItems.forEach((coin) => {
        somme += Number(coin.value) * coin.qty;
      });
      return somme;
    },
    endShiftTotal() {
      let somme = 0;
      this.endShiftDatatableItems.forEach((coin) => {
        somme += Number(coin.value) * coin.qty;
      });
      return somme;
    },
    datatableHeaders: function () {
      return [
        {
          text: this.$t("generic.lang_coin"),
          value: "value",
          sortable: false,
        },
        {
          text: this.$t("erp.lang_quantity"),
          value: "qty",
          sortable: true,
        },
        {
          text: this.$t("generic.lang_total"),
          value: "total",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    loadData() {
      this.loading = true;

      this.axios
        .post(ENDPOINTS.ACCOUNTING.DAILYREPORT.COUNTING_PROTOCOLL, {
          reportID: this.$route.params.id,
        })
        .then((res) => {
          this.startShiftDatatableItems = res.data.data.moneyProtocolStart;
          this.endShiftDatatableItems = res.data.data.moneyProtocolEnd;
        })
        .catch(() => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
